<button class="btn at-border at-cursor-pointer at-justify-center at-align-items-center" [ngClass]="btnClass()" [disabled]="disabled()" [id]="id()">
  @if (startIcon()) {
    <img [src]="startIcon()?.src" [alt]="startIcon()?.alt" />
  }

  @if (label()) {
    <span [ngClass]="{ 'at-ps-2': startIcon(), 'at-pe-2': endIcon() }">
      {{ label() }}
    </span>
  }

  @if (endIcon()) {
    <img [src]="endIcon()?.src" [alt]="endIcon()?.alt" />
  }
</button>
