export const environment = {
  production: false,
  baseUrl: 'https://admin.test.ecommerce-02.boxooo.com',
  defaultLang: 'en',
  xAuthKey: 'uPF3ED1BFJMtom4mQ9EwTlcoGBXq4s5zDBlQjbyCAj15XTGaMcuyejPtCdDWbuuh',
  environment: 'test',
  SENTRY_AUTH_TOKEN:
    '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',

  STATSIG_KEY: 'client-FWxpp9Hwpvt0hX4v4Gm3ikUMC1NLmRrvsPt17dg0aqT',
  statsigEnvironment: 'staging',
  amplitude: {
    key: '4c79e51cdf2bf3b3ca34e5517ac3d226', //660ce104e1150e78cf36aff6485c920e for test
    serverZone: 'EU',
  },
  pusher: {
    key: 'aa63761e8d76df6ec9dd',
    cluster: 'eu',
  },
};
