import { Injectable, signal } from '@angular/core';
import { Toast } from './toast.interface';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  toastSource = new BehaviorSubject<Toast>({
    message: '',
    toastState: 'error',
  });
  showToast = this.toastSource.asObservable();
  error = signal<string>('');
  success = signal<string>('');

  show(toastObject: Toast) {
    this.toastSource.next(toastObject);
  }
}
