import { environment } from '../../../environments/environment';

export const BOXO_APIS = {
  GET_COUNTRIES: `${environment.baseUrl}/api/v1/countries`,
  POST_REGISTER: `${environment.baseUrl}/api/v1/auth/register`,
  POST_LOGIN: `${environment.baseUrl}/api/v1/auth/login`,
  POST_LOGOUT: `${environment.baseUrl}/api/v1/auth/logout`,
  GET_TICKETS: `${environment.baseUrl}/api/v1/tickets`,
  POST_SUPPLIERS: `${environment.baseUrl}/api/v1/suppliers`,
  GET_ELEVATIONS: `${environment.baseUrl}/api/v1/elevations`,
  GET_COFFEE_ORIGINS: `${environment.baseUrl}/api/v1/coffee-origins`,
  GET_COFFEE_VARIETIES: `${environment.baseUrl}/api/v1/coffee-varieties`,
  GET_PRODUCT_TYPES: `${environment.baseUrl}/api/v1/product-types`,
  GET_DELIVERY_OPTIONS: `${environment.baseUrl}/api/v1/delivery-options`,
  GET_COMPOSITIONS: `${environment.baseUrl}/api/v1/compositions`,
  GET_ROAST_LEVELS: `${environment.baseUrl}/api/v1/roast-levels`,
  POST_PRODUCTS: `${environment.baseUrl}/api/v1/products`,
  GET_SUPPLIER_PRODUCTS: `${environment.baseUrl}/api/v1/suppliers/products`,
  GET_PRODUCTS: `${environment.baseUrl}/api/v1/products`,
  UPDATE_PRODUCTS: `${environment.baseUrl}/api/v1/products/{id}/update`,
  GET_PRODUCT_DETAILS: `${environment.baseUrl}/api/v1/products`,
  TOGGLE_PRODUCT_ACTIVATION: `${environment.baseUrl}/api/v1/products/{id}/toggle-activation`,
  GET_SHIPPING_ADDRESS: `${environment.baseUrl}/api/v1/shipping-addresses`,
  POST_SHIPPING_ADDRESS: `${environment.baseUrl}/api/v1/shipping-addresses`,
  GET_CART_ITEMS: `${environment.baseUrl}/api/v1/carts`,
  ADD_TO_CART: `${environment.baseUrl}/api/v1/carts/{reference}/items`,
  UPDATE_CART_ITEMS: `${environment.baseUrl}/api/v1/carts/{reference}/items/{id}`,
  DELETE_CART_ITEMS: `${environment.baseUrl}/api/v1/carts/{reference}/items/{id}`,
  CHECKOUT_CART: `${environment.baseUrl}/api/v1/carts/{reference}/checkout`,
  GET_ORDERS: `${environment.baseUrl}/api/v1/orders`,
  GET_ORDER_DETAILS: `${environment.baseUrl}/api/v1/orders/{id}`,
  GET_SUPPLIER_ORDERS: `${environment.baseUrl}/api/v1/sub-orders`,
  GET_SUB_ORDER_DETAILS: `${environment.baseUrl}/api/v1/sub-orders/{id}`,
  POST_SUPPORT_ORDER: `${environment.baseUrl}/api/v1/orders/{order-id}/sub-orders/{sub-order-id}/support`,
  PUT_SUB_ORDER_ACCEPT: `${environment.baseUrl}/api/v1/sub-orders/{sub-order-id}/accept`,
  PUT_SUB_ORDER_DECLINE: `${environment.baseUrl}/api/v1/sub-orders/{sub-order-id}/decline`,
  PUT_SUB_ORDER_DELIVER: `${environment.baseUrl}/api/v1/sub-orders/{sub-order-id}/deliver`,
  GET_ITEMS_AVAILABLE_STATES: `${environment.baseUrl}/api/v1/sub-orders/{sub-order-id}/items/{item-id}/states`,
  PUT_DELAY_ITEM_SHIPMENT: `${environment.baseUrl}/api/v1/sub-orders/{sub-order-id}/items/{item-id}/delay-shipment`,
  PUT_DELAY_ITEM_DELIVERY: `${environment.baseUrl}/api/v1/sub-orders/{sub-order-id}/items/{item-id}/delay-delivery`,
  PUT_SHIP_ITEM: `${environment.baseUrl}/api/v1/sub-orders/{sub-order-id}/items/{item-id}/ship`,
  PUT_DELIVER_ITEM: `${environment.baseUrl}/api/v1/sub-orders/{sub-order-id}/items/{item-id}/deliver`,
  GET_PROFILE: `${environment.baseUrl}/api/v1/buyers/profile`,
  UPDATE_PROFILE: `${environment.baseUrl}/api/v1/buyers/profile`,
  GET_SUPPLIER_PROFILE: `${environment.baseUrl}/api/v1/suppliers/profile`,
  UPDATE_SUPPLIER_PROFILE: `${environment.baseUrl}/api/v1/suppliers/profile/update`,
  POST_VALIDATE_DELETE_REQUEST: `${environment.baseUrl}/api/v1/buyers/delete-requests/validate`,
  POST_DELETE_ACCOUNT: `${environment.baseUrl}/api/v1/buyers/delete-requests`,
  PUT_TOGGLE_PRODUCT_IN_STOCK: `${environment.baseUrl}/api/v1/products/{id}/toggle-in-stock`,
  POST_REORDER: `${environment.baseUrl}/api/v1/orders/{id}/reorder`,
  POST_OTP_INITIATE: `${environment.baseUrl}/api/v1/otp-verifications/initiate`,
  POST_OTP_COMPLETE: `${environment.baseUrl}/api/v1/otp-verifications/{key}/complete`,
  PUT_RESET_PASSWORD: `${environment.baseUrl}/api/v1/auth/password/reset`,
  GET_NOTIFICATIONS: `${environment.baseUrl}/api/v1/notifications`,
  PATCH_NOTIFICATIONS: `${environment.baseUrl}/api/v1/notifications/{id}`,
};
