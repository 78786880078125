import { Component, input } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'ecommerce-button',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
})
export class ButtonComponent {
  id = input.required<string>();
  label = input<string>();
  startIcon = input<{ src: string; alt: string }>();
  endIcon = input<{ src: string; alt: string }>();
  disabled = input<boolean>();
  btnClass = input<string>();
}
